import { Logout } from '@rtt-libs/auth';
import * as Sentry from '@sentry/browser';
import React from 'react';
import './App.css';
import QRCode from './qr-code_play-store.png';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.NODE_ENV === 'production',
});

const loginPage = `${process.env.REACT_APP_URI_PROTOCOL}${
  process.env.REACT_APP_SUBDOMAIN_LANDING
    ? process.env.REACT_APP_SUBDOMAIN_LANDING.concat('.')
    : ''
}${process.env.REACT_APP_ROOT_DOMAIN}/login`;

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>OSA: online sales agent</h1>
      </header>

      <main>
        <p>
          Встановіть додаток «OSA» в смартфон, і ви отримаєте доступ до всіх
          дистриб'юторів, які працюють у вашому регіоні. Реєструйтеся та
          замовляйте усі товари в додатку «OSA», Ви отримаєте актуальні на
          сьогодні ціни, новинки, не пропустите акційні пропозиції, також
          отримаєте зручний доступ до історії замовлень і повернень.
        </p>

        <div>
          <img
            src={QRCode}
            alt="QR code with URL to Google Play store"
            height="300px"
            width="300px"
          />
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.osa.sale&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              className="App-QRCode"
              alt="Завантажити з Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/ua_badge_web_generic.png"
            />
          </a>
        </div>
      </main>

      <footer>
        <p>
          Зараз ця частина сервісу доступна тільки через додаток для смартфону.
          <br />
          {'Веб-версією можливо користуватися якщо '}
          <a href={loginPage}>зайти</a>
          {" як адміністратор системи або дистриб'ютор."}
        </p>

        <Logout component={<button type="button">Выйти</button>} />
      </footer>
    </div>
  );
};

export default App;
