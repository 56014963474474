import { projectId } from '@rtt-libs/constants';
import LocalStorageHandler from './localStorage';
import { UserInfoType } from '../types';

const profileIdKey = `${projectId}/profile`;

const profileLocalStorage = new LocalStorageHandler<UserInfoType>(
  profileIdKey,
  { parse: JSON.parse, stringify: JSON.stringify },
);

export default profileLocalStorage;
