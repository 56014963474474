import { UsedLocales } from '../types';

const genLocaleHeader = (
  locale?: UsedLocales,
): Record<'Accept-language', UsedLocales> | {} =>
  locale
    ? {
        'Accept-language': locale,
      }
    : {};

export default genLocaleHeader;
