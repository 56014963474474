import Cookies from 'js-cookie';
import { COOKIE_NAME } from '../environment';
import { Token } from '../types';

/**
 * Get authorization token from cookies.
 * Undefined if doesn't exist in browser storage.
 */
const getAuthToken = (): Token | undefined => Cookies.get(COOKIE_NAME);

export default getAuthToken;
