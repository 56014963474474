import Cookies from 'js-cookie';
import { cookieNames, COOKIE_DOMAIN } from '../environment';

/**
 * Remove all cookies from browser storage
 */
const removeCookies = (): void => {
  Object.values(cookieNames).forEach(name => {
    Cookies.remove(name as string, { domain: COOKIE_DOMAIN });
  });
};

export default removeCookies;
