import { DEFAULT_LNG } from '@rtt-libs/constants';
import axios from 'axios';
import { BASE_API_URL } from '../environment';

const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    // TODO: Add reading from localStorage if it's available
    // (naive approach leads to throwing on building landing app)
    // e.g. `localStorage.getItem(I18N_LANG_KEY) || DEFAULT_LNG,` --> `ReferenceError: localStorage is not defined`
    'Accept-language': DEFAULT_LNG,
  },
});

export default api;
