import React, { useEffect, useState } from 'react';
import { getAuthToken } from '../cookieHandlers';
import logoutAll from './logoutEverywhere';
import logout from './logoutHere';

const noop = (): void => undefined;

type Props = {
  component: React.ReactElement;
  all?: boolean;
  callback?: typeof noop;
};

/**
 * Render button with logout onClick handler
 * Don't render when there's no token
 */
const LogoutButton: React.FC<Props> = ({
  component,
  all = false,
  callback = noop,
}) => {
  const [isTokenExist, changeTokenState] = useState(false);
  useEffect(() => {
    changeTokenState(!!getAuthToken());
  }, []);

  if (!isTokenExist) return null;

  const onClick = (): void => {
    if (all) {
      logoutAll();
    } else {
      logout();
    }
    callback();
    changeTokenState(false);
  };

  const ButtonComponent = React.cloneElement(component, { onClick });

  return ButtonComponent;
};

export default LogoutButton;
