interface LocalStorageHandlerOptions<T = string> {
  parse(localStorageValue: string | null): T;
  stringify(value: T): string;
}

export default class LocalStorageHandler<T> {
  protected key: string;

  protected options: Partial<LocalStorageHandlerOptions<T>>;

  constructor(
    key: string,
    options: Partial<LocalStorageHandlerOptions<T>> = {},
  ) {
    this.key = key;
    this.options = options;
  }

  // Don't use getter & setter because them will be using in sagas calls.
  get = () => {
    if (localStorage) {
      const value = localStorage.getItem(this.key);
      if (this.options.parse) return this.options.parse(value);
      return value;
    }
    return null;
  };

  set = (value: T): void => {
    if (localStorage) {
      const strValue = this.options.stringify
        ? this.options.stringify(value)
        : String(value);
      return localStorage.setItem(this.key, strValue);
    }
    return undefined;
  };

  remove = () => {
    if (localStorage) {
      return localStorage.removeItem(this.key);
    }
    return undefined;
  };
}
