import { KEY_STORE_NAME } from '@rtt-libs/constants';
import { EncryptionKey } from '@rtt-libs/types';
import { clear, del, get, set, Store } from 'idb-keyval';
import { getAuthToken } from '../cookieHandlers';
import { Encryption } from '../encrypt';
import { genKeyName } from './genKeyName';

export const keyStore = () => new Store('rtt-distr', KEY_STORE_NAME);

const getEncKey = () => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('empty token value');
  }

  return token.slice(0, 16);
};

export const getOrderEncKeys = (idList: string[]) => {
  const encKey = getEncKey();

  return Promise.all(
    idList.map(id =>
      get<string>(id, keyStore()).then(key => {
        if (!key) {
          throw new Error('empty key value');
        }
        return JSON.parse(Encryption.decrypt(key, encKey)) as EncryptionKey;
      }),
    ),
  );
};

export const setOrderEncKeys = (keys: EncryptionKey[]) => {
  const encKey = getEncKey();

  return Promise.all(
    keys.map(key =>
      set(
        'order_id' in key
          ? key.order_id
          : genKeyName(key.rtt_id, key.manager_id),
        Encryption.encrypt(JSON.stringify(key), encKey),
        keyStore(),
      ),
    ),
  );
};

export const delOrderEncKeys = (keys: string[]) => {
  return Promise.all(keys.map(key => del(key, keyStore())));
};

export const clearOrderEncKeys = () => clear(keyStore());
