export const BASE_API_URL = process.env.REACT_APP_API_URI_AUTH;

export const ENDPOINTS = {
  changePassword: '/user/change-password',
  profile: 'user/profile',
  locale: 'user/locale',
  logout: 'logout',
  logoutAll: 'logoutAll',
};

export const PROTOCOL = process.env.REACT_APP_URI_PROTOCOL || 'http://';

const validateExpiresCookie = (value?: string) => {
  if (typeof value !== 'string') return undefined;
  const numericExpires = parseInt(value, 10);

  if (Number.isNaN(numericExpires)) return undefined;
  return numericExpires;
};

export const COOKIE_DOMAIN = `.${process.env.REACT_APP_ROOT_DOMAIN}`;
export const COOKIE_EXPIRES =
  validateExpiresCookie(process.env.REACT_APP_COOKIE_EXPIRES) || 365;

export const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME || 'rtt-auth';
export const cookieNames = {
  auth: COOKIE_NAME,
};

export const SUBDOMAIN_ADMIN = process.env.REACT_APP_SUBDOMAIN_ADMIN || 'admin';
export const SUBDOMAIN_DISTRIBUTOR =
  process.env.REACT_APP_SUBDOMAIN_DISTRIBUTOR || 'distr';
export const SUBDOMAIN_RTT = process.env.REACT_APP_SUBDOMAIN_RTT || 'rtt';
export const SUBDOMAIN_LANDING = process.env.REACT_APP_SUBDOMAIN_LANDING || '';

const loginRoute = '/login';

export const ROUTE_WITHOUT_PRIVILEGES = '/';
export const LOGIN_PAGE = `${PROTOCOL}${
  SUBDOMAIN_LANDING && `${SUBDOMAIN_LANDING}.`
}${process.env.REACT_APP_ROOT_DOMAIN}${loginRoute}`;
