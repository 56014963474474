import { ENDPOINTS } from '../environment';
import { UsedLocales, Token } from '../types';
import genLocaleHeader from './localeHeader';
import apiInstance from './setup';

const logoutAllApi = (token: Token, locale?: UsedLocales) =>
  apiInstance.post<void>(ENDPOINTS.logoutAll, undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...genLocaleHeader(locale),
    },
  });

export default logoutAllApi;
