import { logoutAllApi } from '../api';
import { getAuthToken, removeCookies } from '../cookieHandlers';
import { LOGIN_PAGE } from '../environment';
import { clearOrderEncKeys } from '../orderKeyStore';
import { profileLocalStorage } from '../profileStorage';

/**
 * Logout from all devices
 */
const logoutEverywhere = async (): Promise<void> => {
  const token = getAuthToken();

  removeCookies();
  clearOrderEncKeys();

  profileLocalStorage.remove();

  try {
    if (token) await logoutAllApi(token);

    window.location.href = LOGIN_PAGE;
  } catch (e) {
    // TODO: [ERROR-HANDLER]
    // eslint-disable-next-line no-console
    console.log(e.message);
  }
};

export default logoutEverywhere;
