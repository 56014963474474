import { roles } from '@rtt-libs/constants';
import {
  COOKIE_DOMAIN,
  PROTOCOL,
  SUBDOMAIN_ADMIN,
  SUBDOMAIN_DISTRIBUTOR,
  SUBDOMAIN_RTT,
} from '../environment';
import { UserInfoType } from '../types';

const makeRedirectUrl = (subdomain: string) =>
  `${PROTOCOL}${subdomain}${COOKIE_DOMAIN}`;

const rolesRedirectionConfig = {
  [roles.SYSTEM_ADMIN]: SUBDOMAIN_ADMIN,
  [roles.SYSTEM_MODERATOR]: SUBDOMAIN_ADMIN,
  [roles.DISTR_OWNER]: SUBDOMAIN_DISTRIBUTOR,
  [roles.DISTR_ADMIN]: SUBDOMAIN_DISTRIBUTOR,
  [roles.DISTR_SUPERVISOR]: SUBDOMAIN_DISTRIBUTOR,
  [roles.DISTR_MANAGER]: SUBDOMAIN_DISTRIBUTOR,
  [roles.RTT_OWNER]: SUBDOMAIN_RTT,
};

/**
 * Generate url to appropriate app assigned to role.
 * @param role - role from an user profile data
 * @param onWrongRole - handler when passed unexpected role
 */
export const generateRedirectUrl = (
  role: string,
  onWrongRole?: Function,
): string | null => {
  const isRoleAcceptable = Object.keys(rolesRedirectionConfig).includes(role);

  if (!isRoleAcceptable) {
    if (typeof onWrongRole === 'function') onWrongRole();
    return null;
  }

  const appSubdomain = rolesRedirectionConfig[role];

  return makeRedirectUrl(appSubdomain);
};

export const browserRedirect = (url: string): void => {
  if (/^https?:\/\//.test(url)) {
    window.location.href = url;
  } else {
    throw new Error("The passed `url` doesn't have protocol");
  }
};

/**
 * Redirect to assigned to role app.
 * @param role - role returner in user profile data
 * @param redirect - handler for generated url, by default redirects by location
 * @param onWrongRole - handler for redirection when role is unexpected
 */
export const redirectToApp = (
  role: string,
  redirect: typeof browserRedirect = browserRedirect,
  onWrongRole?: () => void,
): void => {
  const redirectUrl = generateRedirectUrl(role, onWrongRole);

  if (redirectUrl) redirect(redirectUrl);
};

export const generateRedirectUrlByUser = (
  user: UserInfoType,
  onDeny?: (message: string) => void,
): string | null => {
  if (user.company && user.company.status === 'disabled') {
    return (
      (typeof onDeny === 'function' && onDeny('Your company deactivated')) ||
      null
    );
  }

  if (!user.is_active) {
    return (
      (typeof onDeny === 'function' &&
        onDeny('Your account is deactivated by your administrator')) ||
      null
    );
  }

  return generateRedirectUrl((user && user.role) || '', onDeny);
};

export const redirectToAppByUser = (
  user: UserInfoType,
  redirect: typeof browserRedirect = browserRedirect,
  onDeny?: () => void,
): void => {
  const redirectUrl = generateRedirectUrlByUser(user, onDeny);

  if (redirectUrl) redirect(redirectUrl);
};
